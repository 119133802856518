import { axios } from "../../config/axios"

class CIMDService {
     async getAllCIMDs() {
        return await axios.get("/cimd/all").then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getCIMDById(id) {
        return await axios.get("/cimd/getbyid/"+id)
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async createCIMD(cimd) {
        return await axios.post("/cimd/create", cimd)
        .catch((err) => console.log(err));
    }
    async updateCIMD(id,cimd){
        return await axios.put("/cimd/update/"+id, cimd)
        .catch((err) => console.log(err));
    }
    async deleteCIMD(id){
        return await axios.delete("/cimd/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new CIMDService();