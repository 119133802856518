import React from "react";
import "../styles/Home.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <div className="Move-body">
      <h1 className="home-title">WELD-VR data interface</h1>
      <p className="home-undertitle">
        Please contact project participants for further instructions on use:{" "}
        <a href="https://www.hv.se/forskning/forskningsprojekt/teknik/weldvr/">
          WELD-VR project webpage
        </a>
      </p>

      <div className="card-container">
        <div
          className="card-item"
          onClick={() => navigate("/materialtypes/prodprocess")}
        >
          <h3 className="number">01</h3>
          <h3 className="card-title">Create material type</h3>
          <p className="card-p">
            This is your <strong>first step</strong> before uploading. This
            includes creating material classes, alloys, production process and
            condition and indepth microstructure index
          </p>
        </div>
        <div
          className="card-item"
          onClick={() => navigate("/uploadMaterialForm")}
        >
          <h3 className="number">02</h3>
          <h3 className="card-title">Upload material</h3>
          <p className="card-p">Upload material and its information</p>
        </div>
        <div className="card-item" onClick={() => navigate("/viewMaterial")}>
          <h3 className="number">03</h3>
          <h3 className="card-title">View Material</h3>
          <p className="card-p">
            View, update or delete material and there informations
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
