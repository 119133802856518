import React, { useState, useEffect } from "react";

const Debug = () => {
  const [sessionData, setSessionData] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const hostname = window.location.hostname;
  const api = `https://${hostname}:4000`;

  useEffect(() => {
    const fetchSessionData = async () => {
      const response = await fetch(api + "/get-session", {
        credentials: "include",
      });
      const data = await response.text();
      setSessionData(data);
    };

    const fetchSessionId = async () => {
      const response = await fetch(api + "/get-session-id", {
        credentials: "include",
      });
      const sessionId = await response.text();
      setSessionId(sessionId);
    };

    fetchSessionData();
    fetchSessionId();
  }, []);

  return (
    <div className="Move-body">
      <h1>Debug</h1>
      <h2>Session Data</h2>
      <pre>{sessionData}</pre>
      <h2>Session ID</h2>
      <pre>{sessionId}</pre>
    </div>
  );
};

export default Debug;
