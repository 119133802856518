import "../../../styles/UploadMaterialForm.css";
import matClassService from "../../../service/matClassService";
import "../../../styles/MaterialTypes.css";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const CreateMaterialClassForm = () => {

    const [message, setMessage] = useState('');
    const formData = new FormData();
    const navigate = useNavigate();

    const handle = (e) => {
        if (e.target.value) formData.set(e.target.id, e.target.value);
    }

    const createMatClass = async (e) => {
        try {
            e.preventDefault();
            console.log("uploaded:" + formData);
            const response = await matClassService.createMatClass(formData);
            if (response) navigate('/materialtypes/materialclass');
        } catch (err) {
            setMessage('A problem has occured');
            console.log("error occured");
        }
    }

    return (
        <div>
            <form className="matForm" onSubmit={(e) => createMatClass(e)}>
            <h2 className="title">Create Material Class type</h2>
            <p className="undertitle">{message}</p>
            <p className="undertitle">Create Material Class type for the Weld-VR environment that can be used in a material</p>
                <label className="labelTextarea">Material Class Name</label>
                <input id="MatClassName" onChange={(e) => handle(e)} className="textInput" type="text" placeholder="Name of the sample" /> <br />
                <input className="uploadBtn" type="submit" value="Create"/>
            </form>
        </div>
    )
};

export default CreateMaterialClassForm;

