import "../styles/UploadMaterialForm.css";
import CIMDService from "../service/cimdService";
import MatClassService from "../service/matClassService";
import AlloyService from "../service/alloyService";
import ProdProcessService from "../service/prodProcessService";
import React, { useEffect, useState } from "react";
import materialService from "../service/materialService";
import { useParams, useNavigate } from "react-router-dom";

const UpdateMaterialForm = () => {
  const [material, setMaterial] = useState({
    Id: "",
    ImgPath: "",
    ImgName: "",
    Type: "",
    MatClassId: "",
    AlloyId: "",
    ProdProcessId: "",
    CIMDId: "",
    Magnification: "",
    Comment: "",
    SampleName: "",
    UserId: "",
  });
  const [cimds, setCimds] = useState([]);
  const [mats, setMats] = useState([]);
  const [alloys, setAlloys] = useState([]);
  const [prods, setProds] = useState([]);
  const [state, setState] = useState("");
  const formData = new FormData();
  const { id } = useParams();
  const navigate = useNavigate();
  const hostname = window.location.hostname;

  useEffect(() => {
    const fetchMaterial = async () => {
      try {
        const response = await materialService.getMaterialById(id);
        if (response) {
          setMaterial(response.data);
          console.log(material);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    fetchMaterial();

    /*  CIMD */
    const fetchCimds = async () => {
      try {
        const response = await CIMDService.getAllCIMDs();
        if (response) setCimds(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchCimds();

    /*  MatClass */
    const fetchMats = async () => {
      try {
        const response = await MatClassService.getAllMatClass();
        if (response) setMats(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchMats();

    /*  Alloy */
    const fetchAlloys = async () => {
      try {
        const response = await AlloyService.getAllAlloys();
        if (response) setAlloys(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchAlloys();

    /*  ProdProcess */
    const fetchProds = async () => {
      try {
        const response = await ProdProcessService.getAllProdProcess();
        if (response) setProds(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchProds();
  }, []);

  const handle = (e) => {
    /* const inputData = {...data}; */
    if (e.target.value) formData.set(e.target.id, e.target.value);
    console.log(formData);
  };
  const handleImage = (e) => {
    if (e.target.value) {
      formData.set(e.target.id, e.target.files[0], e.target.value);
    } else {
      formData.delete(e.target.id);
    }

    console.log(formData);
  };

  const updateMaterial = async (e) => {
    alert("Your material has been uploaded!");
    try {
      e.preventDefault();
      const response = await materialService.updateMaterial(id, formData);
      console.log("It worked!");
      if (response) navigate("/viewMaterial");
    } catch (err) {
      setState("The material has not been uploaded due to an error!");
      console.log("error occured");
    }
  };

  return (
    <div className="Move-body">
      <h1 className="title">Update Material</h1>
      <p className="undertitle">Update material for the Weld-VR environment</p>
      <p className="undertitle">{state}</p>

      <form onSubmit={(e) => updateMaterial(e)}>
        <div className="p-underline">
          <p>Information about the image of the material</p>
        </div>

        <img
          className="materialImage"
          src={`https://${hostname}:4000/thumb/${material.ImgPath}`}
          alt="Material"
        ></img>
        <label>
          Upload NEW image on material <br />
          <input
            id="image"
            onChange={(e) => handleImage(e)}
            className="textInput"
            type="file"
          />
        </label>

        <div className="p-underline">
          <p>Information about the material</p>
        </div>

        <label>
          Sample Name <br />
          <input
            required
            id="SampleName"
            onChange={(e) => handle(e)}
            className="textInput"
            type="text"
            placeholder="Name of the sample"
            defaultValue={material.SampleName}
          />{" "}
          <br />
          <br />
        </label>

        {/* TODO value=0 ändra så den inte mappar mot noll / möjligtvis enligt id  */}
        <label>
          CIMD (Condition and In-depth microstructure details) <br />
          <select id="CIMDId" onChange={(e) => handle(e)} className="cimdBox">
            <option value="0">- Empty -</option>
            {cimds.map((cimds) => {
              return (
                <option
                  key={cimds.Id}
                  value={cimds.Id}
                  selected={cimds.Id == material.CIMDId ? "SELECTED" : ""}
                >
                  {cimds.CIMDName}
                </option>
              );
            })}
          </select>{" "}
          <br />
          <br />
        </label>

        <div className="flex-container">
          <label>
            Material class
            <select
              required
              id="MatClassId"
              onChange={(e) => handle(e)}
              className="materialBox"
            >
              <option value="0">- Empty -</option>
              {mats.map((mat) => {
                return (
                  <option
                    key={mat.Id}
                    value={mat.Id}
                    selected={mat.Id == material.MatClassId ? "SELECTED" : ""}
                  >
                    {mat.MatClassName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="labelAlloyBox">
            Alloy
            <select
              id="AlloyId"
              onChange={(e) => handle(e)}
              className="alloyBox"
            >
              <option value="0">- Empty -</option>
              {alloys.map((alloy) => {
                return (
                  <option
                    key={alloy.Id}
                    value={alloy.Id}
                    selected={alloy.Id == material.AlloyId ? "SELECTED" : ""}
                  >
                    {alloy.AlloyName}
                  </option>
                );
              })}
            </select>{" "}
            <br />
            <br />
          </label>
        </div>

        <div className="flex-container">
          <label>
            Production process
            <select
              id="ProdProcessId"
              onChange={(e) => handle(e)}
              className="prodBox"
            >
              <option value="0">- Empty -</option>
              {prods.map((prod) => {
                return (
                  <option
                    key={prod.Id}
                    value={prod.Id}
                    selected={
                      prod.Id == material.ProdProcessId ? "SELECTED" : ""
                    }
                  >
                    {prod.ProdProcessName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="labelMagBox">
            Magnification
            <input
              id="Magnification"
              onChange={(e) => handle(e)}
              className="magBox"
              type="number"
              defaultValue={material.Magnification}
            />{" "}
            <br />
            <br />
          </label>
        </div>

        <label className="labelTextarea">
          Comment <br />
          <textarea
            id="Comment"
            onChange={(e) => handle(e)}
            className="textareaBox"
            placeholder="Comment about the material"
            defaultValue={material.Comment}
          ></textarea>{" "}
          <br />
        </label>

        <input className="uploadBtn" value="Update material" type="submit" />
      </form>
    </div>
  );
};

export default UpdateMaterialForm;
