import React from "react";

const MaterialCard = ({ material, navigate, hostname }) => {
  return (
    <div onClick={() => navigate('viewMaterialDetail/' + material.Id)}>
      <div className="materialItem">
        <img className="materialImage" src={`https://${hostname}:4000/thumb/${material.ImgPath}`} alt="Material" />
        <div>
          <p className="materialText">{material.SampleName}</p>
          <p className="material-Under">Mag: {material.Magnification}</p>
          {/* <p className="material-ID">ID {material.Id}</p> */}
        </div>
      </div>
    </div>
  );
}

export default MaterialCard;
