import React from "react";
import prodProcessService from "../../../service/prodProcessService";
import "../../../styles/MaterialTypes.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ProdProcessList = () => {
  const [prodProcess, setProdProcess] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getProdProcess = async () => {
      try {
        const response = await prodProcessService.getAllProdProcess();
        if (response) {
          setProdProcess(response.data);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    getProdProcess();
  }, []);

  const Delete = async (id) => {
    try {
      await prodProcessService.deleteProdProcess(id);
      window.location.reload(false);
    } catch (err) {
      console.log("error occured" + err);
    }
  };
  const submit = (props) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete? " + props.ProdProcessName,
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(props.Id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="CIMD-div">
      <Link to="/materialtypes/prodprocess/create">
        <button className="createCimdMatClass">
          Create Production Process Type
        </button>
      </Link>
      <div className="CIMD-MatClass-div">
        <h1>Production Process</h1>
        <table>
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {prodProcess.map((prodprocess) => {
              return (
                <tr key={prodprocess.Id}>
                  {/* <td>{prodprocess.Id}</td> */}
                  <td className="tdName">{prodprocess.ProdProcessName}</td>
                  <td>
                    <button
                      className="Cimd-MatClass-Update"
                      onClick={() => navigate("update/" + prodprocess.Id)}
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="Cimd-MatClass-Delete"
                      onClick={() => submit(prodprocess)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProdProcessList;
