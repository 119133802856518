import "../styles/Login.css";
import React, { useEffect, useState } from "react";

const UploadPowerpoint = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      return;
    }

    // TODO: add logic to upload 'selectedFile' to your server
    console.log(`Uploading file: ${selectedFile.name}`);
  };

  return (
    <div className="Move-body">
      <form onSubmit={handleUpload}>
        <input type="file" accept=".ppt,.pptx" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default UploadPowerpoint;
