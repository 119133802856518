import { axios } from "../../config/axios"

class MatClassService {
     async getAllMatClass() {
        return await axios.get("/matclass/all").then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getMatClassById(id) {
        return await axios.get("/matclass/getbyid/"+id)
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async createMatClass(matclass) {
        return await axios.post("/matclass/create", matclass)
        .catch((err) => console.log(err));
    }
    async updateMatClass(id,matclass){
        return await axios.put("/matclass/update/"+id, matclass)
        .catch((err) => console.log(err));
    }
    async deleteMatClass(id){
        return await axios.delete("/matclass/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new MatClassService();