import React from 'react'
import matClassService from '../../../service/matClassService';
import "../../../styles/MaterialTypes.css";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const MatClassList = () => {
  const [matClass, setMatClass] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCIMD = async () => {
      try {
        const response = await matClassService.getAllMatClass();
        if (response) {
          setMatClass(response.data)
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    }
    getCIMD()
  }, []);

  const Delete = async (id) => {
    try {
      await matClassService.deleteMatClass(id);
      window.location.reload(false);
    } catch (err) {
      console.log("error occured" + err);
    }
  }
  const submit = (props) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete? " + props.MatClassName,
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(props.Id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className='CIMD-div'>
    <Link to="/materialtypes/materialclass/create">
      <button className='createCimdMatClass'>Create Material Class Type</button>
    </Link>
      <div className='CIMD-MatClass-div'>
      <h1>Material Class</h1>
      <table>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Name</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {matClass.map((matclass) => {
            return (
              <tr key={matclass.Id}>
                {/* <td>{matclass.Id}</td> */}
                <td className='tdName'>{matclass.MatClassName}</td>
                <td>
                  <button className='Cimd-MatClass-Update' onClick={() => navigate('update/' + matclass.Id)}>Update</button>
                  </td>
                <td>
                  <button className='Cimd-MatClass-Delete' onClick={() => submit(matclass)}>Delete</button>
                  </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
    </div>
  )
}

export default MatClassList;