import { axios } from "../../config/axios"

class AlloyService {
     async getAllAlloys() {
        return await axios.get("/alloy/all")
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getAlloyById(id) {
        return await axios.get("/alloy/getbyid/"+id)
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async createAlloy(alloy) {
        return await axios.post("/alloy/create", alloy)
        .catch((err) => console.log(err));
    }
    async updateAlloy(id,alloy){
        return await axios.put("/alloy/update/"+id, alloy)
        .catch((err) => console.log(err));
    }
    async deleteAlloy(id){
        return await axios.delete("/alloy/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new AlloyService();