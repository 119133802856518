import "../../../styles/UploadMaterialForm.css";
import CIMDService from "../../../service/cimdService";
import "../../../styles/MaterialTypes.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateCIMDForm = () => {
  const [message, setMessage] = useState("");
  const [cimdData, setCIMDData] = useState({ Id: "", Name: "" });
  const { id } = useParams();
  const formData = new FormData();
  const navigate = useNavigate();

  const handle = (e) => {
    if (e.target.value) formData.set(e.target.id, e.target.value);
  };

  useEffect(() => {
    const getCIMD = async () => {
      try {
        const response = await CIMDService.getCIMDById(id);
        if (response) {
          setCIMDData(response.data);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    getCIMD();
  }, [id]);

  const updateCIMD = async (e) => {
    try {
      e.preventDefault();
      const response = await CIMDService.updateCIMD(id, formData);
      console.log("uploaded:" + formData);
      if (response) navigate("/materialtypes/cimd");
    } catch (err) {
      setMessage("A problem has occured");
      console.log("error occured");
    }
  };

  return (
    <div>
      <form className="matForm" onSubmit={(e) => updateCIMD(e)}>
        <h2 className="title">Update CIMD {cimdData.Id}</h2>
        <p className="undertitle">{message}</p>
        <p className="undertitle">
          Update CIMD type for the Weld-VR environment that can be used in a
          material
        </p>
        <label className="labelTextarea">CIMD Name</label>
        <input
          id="CIMDName"
          onChange={(e) => handle(e)}
          className="textInput"
          type="text"
          placeholder="Name of the sample"
          defaultValue={cimdData.CIMDName}
        />{" "}
        <br />
        <input className="uploadBtn" value="Update" type="submit" />
      </form>
    </div>
  );
};

export default UpdateCIMDForm;
