import "../../../styles/UploadMaterialForm.css";
import alloyService from "../../../service/alloyService";
import "../../../styles/MaterialTypes.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateAlloyForm = () => {
  const [message, setMessage] = useState("");
  const [alloyData, setAlloyData] = useState([]);
  const { id } = useParams();
  const formData = new FormData();
  const navigate = useNavigate();

  const handle = (e) => {
    if (e.target.value) formData.set(e.target.id, e.target.value);
  };

  useEffect(() => {
    const getAlloys = async () => {
      try {
        const response = await alloyService.getAlloyById(id);

        if (response) {
          setAlloyData(response.data);
          console.log(alloyData);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    getAlloys();
  }, [id]);

  const UpdateAlloy = async (e) => {
    try {
      e.preventDefault();
      const response = await alloyService.updateAlloy(id, formData);
      if (response) navigate("/materialtypes/alloy");
      console.log("uploaded:" + formData);
    } catch (err) {
      setMessage("A problem has occured");
      console.log("error occured");
    }
  };

  return (
    <div>
      <form className="matForm" onSubmit={(e) => UpdateAlloy(e)}>
        <h2 className="title">Update Alloy {alloyData.Id}</h2>
        <p className="undertitle">{message}</p>
        <p className="undertitle">
          Update Alloy type for the Weld-VR environment that can be used in a
          material
        </p>
        <label className="labelTextarea">Alloy Name</label>
        <input
          id="AlloyName"
          onChange={(e) => handle(e)}
          className="textInput"
          type="text"
          placeholder="Name of the sample"
          defaultValue={alloyData.AlloyName}
        />{" "}
        <br />
        <input className="uploadBtn" value="Update" type="submit" />
      </form>
    </div>
  );
};

export default UpdateAlloyForm;
