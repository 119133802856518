import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import UploadMaterialForm from "./pages/UploadMaterialForm";
import ViewMaterial from "./pages/ViewMaterial";
import ViewMaterialDetail from "./pages/ViewMaterialDetail";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Debug from "./pages/Debug";
import UploadPowerpoint from "./pages/UploadPowerpoint";
import DebugNav from "./layouts/debugNav";
import MaterialTypes from "./layouts/MaterialTypesNavigation";
import CIMD from "./pages/MaterialTypesPages/CIMD/CIMDList";
import CreateCIMDForm from "./pages/MaterialTypesPages/CIMD/CreateCIMD";
import UpdateCIMDForm from "./pages/MaterialTypesPages/CIMD/UpdateCIMD";
import MatClassList from "./pages/MaterialTypesPages/MatClass/MatClassList";
import CreateMaterialClassForm from "./pages/MaterialTypesPages/MatClass/CreateMaterialClass";
import UpdateMatClassForm from "./pages/MaterialTypesPages/MatClass/UpdateMaterialClass";
import UpdateAlloyForm from "./pages/MaterialTypesPages/Alloy/UpdateAlloy";
import CreateAlloyForm from "./pages/MaterialTypesPages/Alloy/CreateAlloy";
import AlloyList from "./pages/MaterialTypesPages/Alloy/AlloyList";
import ProdProcessList from "./pages/MaterialTypesPages/ProdProcess/ProdProcessList";
import UpdateProdProcessForm from "./pages/MaterialTypesPages/ProdProcess/UpdateProdProcess";
import CreateProdProcssForm from "./pages/MaterialTypesPages/ProdProcess/CreateProdProcess";
import UpdateMaterialForm from "./pages/UpdateMaterialForm";

import useAuth from "./hooks/useAuth";

function App() {
  const navNames = [
    "Home",
    "debug",
    "Create Material Template",
    "View Material",
    "Upload Material",
    "Contact",
  ];

  const navNames2 = [];
  // utloggad navbar
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      setAuth({ loggedin: true });
    }
  }, [setAuth]);

  const handleLogout = () => {
    setAuth({ loggedIn: false });
    localStorage.removeItem("isLoggedin");
  };

  return (
    <Router>
      <div className="App">
        <>
          {auth?.loggedIn ? (
            <DebugNav links={navNames} />
          ) : (
            <DebugNav links={navNames2} />
          )}
        </>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="/debug" element={<Debug />} />

          {auth?.loggedIn ? (
            <>
            <Route path="/home" element={<Home />} />
              <Route
                path="/uploadMaterialForm"
                element={<UploadMaterialForm />}
              />
              <Route path="/viewMaterial" element={<ViewMaterial />} />
              <Route
                path="/viewMaterial/viewMaterialDetail/:id"
                element={<ViewMaterialDetail />}
              />
              <Route
                path="/viewMaterial/update/:id"
                element={<UpdateMaterialForm />}
              />
              <Route path="/materialtypes" element={<MaterialTypes />}>
                <Route path="cimd" element={<CIMD />} />
                <Route path="cimd/create" element={<CreateCIMDForm />} />
                <Route path="cimd/update/:id" element={<UpdateCIMDForm />} />
                <Route path="materialclass" element={<MatClassList />} />
                <Route
                  path="materialclass/create"
                  element={<CreateMaterialClassForm />}
                />
                <Route
                  path="materialclass/update/:id"
                  element={<UpdateMatClassForm />}
                />
                <Route path="alloy" element={<AlloyList />} />
                <Route path="alloy/create" element={<CreateAlloyForm />} />
                <Route path="alloy/update/:id" element={<UpdateAlloyForm />} />
                <Route path="prodprocess" element={<ProdProcessList />} />
                <Route
                  path="prodprocess/create"
                  element={<CreateProdProcssForm />}
                />
                <Route
                  path="prodprocess/update/:id"
                  element={<UpdateProdProcessForm />}
                />
              </Route>
            </>
          ) : (
            <>
              <Route path="/materialtypes" element={<Login />} />
              <Route path="/viewMaterial" element={<Login />} />
              <Route path="/uploadMaterialForm" element={<Login />} />
              <Route path="/uploadPowerpoint" element={<UploadPowerpoint />} />
            </>
          )}
          <Route path="/*" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
