import "../../../styles/UploadMaterialForm.css";
import matClassService from '../../../service/matClassService';
import "../../../styles/MaterialTypes.css";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const UpdateMatClassForm = () => {
    
    const [message, setMessage] = useState('');
    const [matClassData, setMatClassData] = useState({Id:'',Name:''});
    const { id } = useParams();
    const formData = new FormData();
    const navigate = useNavigate();

    const handle = (e) => {
      if (e.target.value) formData.set(e.target.id, e.target.value);
    };

    useEffect(() => {
        const getMatClass = async () => {
          try {
            const response = await matClassService.getMatClassById(id);
            if (response) {
              setMatClassData(response.data);
            }
          } catch (err) {
            console.log("error occured" + err);
          }
        }
        getMatClass()
      }, [id]);

    const UpdateMatClass = async (e) => {
        try {
            e.preventDefault();
            const response = await matClassService.updateMatClass(id,formData); 
            if (response) navigate('/materialtypes/materialclass');
            console.log("uploaded:" + formData);
        } catch (err) {
            setMessage('A problem has occured');
            console.log("error occured");
        }
    };

    return (
        <div>
            <form className="matForm" onSubmit={(e) => UpdateMatClass(e)}>
            <h2 className="title">Update MatClass {matClassData.Id}</h2>
            <p className="undertitle">{message}</p>
            <p className="undertitle">Update Material Class type for the Weld-VR environment that can be used in a material</p>
                <label className="labelTextarea">Material Class Name</label>
                <input id="MatClassName" onChange={(e) => handle(e)} className="textInput" type="text" placeholder="Name of the sample" defaultValue={matClassData.MatClassName}/> <br />
                <input className="uploadBtn" value="Update" type="submit" />
            </form>
        </div>
    )
};

export default UpdateMatClassForm;

