import Axios from "axios";

const hostname = window.location.hostname;
const api = `https://${hostname}:4000`;

export const axios = Axios.create({
    baseURL: api,
    withCredentials: true,
    headers: {"Content-Type": "application/json"},
    timeout: 5000,
});
