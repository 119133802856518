import "../styles/Login.css";
import React, { useEffect, useState } from "react";
import UserService from "../service/userService";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

// import { useContext } from "react";
// import UserContext from "../context/userContext";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const { auth, setAuth } = useAuth();
  const [showPwd, setShowPwd] = useState(false); /* show/hide password */
  const [errorMessage, setErrorMessage] = useState(null); /* error message */

  const handleRegister = async (e) => {
    e.preventDefault();

    // Get form data directly from the form fields when the form is submitted
    const formData = new FormData();
    formData.set("FirstName", e.target.fname.value);
    formData.set("LastName", e.target.lname.value);
    formData.set("Email", e.target.email.value);
    formData.set("Password", e.target.losen.value);

    try {
      const response = await UserService.createUser(formData);
      if (response.data) {
        console.log("registration response:", response.data);
        navigate("/login");
      }
    } catch (err) {
      setErrorMessage("Registration failed."); // Set error message
      console.log("error occured");
    }
  };
  useEffect(() => {
    console.log("Use effect");
    const checkUser = async () => {
      await UserService.checkUserLoggedIn().then((response) => {
        setAuth(response.data);
        console.log(response);
        console.log(response?.data);
        // console.log(auth);
      });
    };
    try {
      checkUser();
    } catch (err) {
      console.log("Error: " + err);
    }
  }, []);

  // const handle = (e) => {
  //   if (e.target.value) formData.set(e.target.id, e.target.value);
  // };

  const togglePwd = (e) => {
    e.preventDefault();
    setShowPwd(!showPwd);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Get form data directly from the form fields when the form is submitted
    const formData = new FormData();
    formData.set("Username", e.target.Username.value);
    formData.set("HashPass", e.target.HashPass.value);

    try {
      const response = await UserService.getUserLogin(formData);
      if (response.data) {
        setAuth({ loggedIn: true, user: response.data });
        console.log("login response:", response.data);
        navigate("/home");
      }
    } catch (err) {
      setErrorMessage("Invalid username or password."); // Set error message
      console.log("error occured");
    }
  };

  const signUpButton = document.getElementById("signUp");
  const signInButton = document.getElementById("signIn");
  const container = document.getElementById("loginsignup");

  if (signUpButton) {
    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });
  }
  if (signInButton) {
    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
  }

  return (
    <div className="Login">
      <>
        <div className="loginsignup" id="loginsignup">
          <div className="form-container sign-in-container">
            <form className="login-form" onSubmit={(e) => handleLogin(e)}>
              <h1>Sign in</h1>
              <input
                placeholder="Enter username"
                className="username"
                type="text"
                id="Username"
                // onChange={(e) => handle(e)}
                required
              />
              <input
                placeholder="Enter password"
                className="password"
                id="HashPass"
                type={showPwd ? "text" : "password"}
                required
              />
              <a href="#">Forgot your password?</a>
              {errorMessage && <p className="error">{errorMessage}</p>}{" "}
              {/* Render error message */}
              <button className="signInBtn" value="Login" type="submit">
                Sign In
              </button>
            </form>
          </div>
          <div className="form-container sign-up-container">
            <form onSubmit={(e) => handleRegister(e)}>
              <h1>Create Account</h1>
              <input
                type="text"
                placeholder="First name"
                name="fname"
                required
              />
              <input
                type="text"
                placeholder="Last name"
                name="lname"
                required
              />
              <input type="text" placeholder="Email" name="email" required />
              <input
                type="password"
                placeholder="Password"
                name="losen"
                required
              />
              <button type="submit">Sign Up</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome!</h1>
                <button className="ghost" id="signIn">
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Register here and start your journey with us</p>
                <button className="ghost" id="signUp">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Login;
