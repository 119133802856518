import { axios } from "../../config/axios"

class UserService {
    async getAllUsers() {
        return await axios.get("/user/all").then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getUserLogin(user) {
        return await axios.post("/user/login", user, {withCredentials: true})
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async checkUserLoggedIn() {
        return await axios.get("/user/login", {withCredentials: true})
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
   
    async createUser(user) {
        return await axios.post("/user/create", user)
        .catch((err) => console.log(err));
    }
    async updateUser(id,user){
        return await axios.put("/user/update/"+id, user)
        .catch((err) => console.log(err));
    }
    async deleteUser(id){
        return await axios.delete("/user/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new UserService();
