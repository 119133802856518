import "../styles/ViewMaterialDetail.css";
import "../styles/MaterialTypes.css";
import React, { useEffect, useState } from "react";
import materialService from "../service/materialService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ViewMaterialDetail = () => {
  // const [message, setMessage] = useState('');
  const [material, setMaterial] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  var hostname = window.location.hostname;

  useEffect(() => {
    const getMaterial = async () => {
      try {
        const response = await materialService.getMaterialById(id);
        console.log(response.data);
        if (response) {
          setMaterial(response.data);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    getMaterial();
  }, []);

  const Delete = async () => {
    try {
      await materialService.deleteMaterial(id);
      navigate("/viewMaterial");
    } catch (err) {
      console.log("error occured" + err);
      navigate("/viewMaterial");
    }
  };

  const Update = async () => {
    try {
      navigate("/viewMaterial/update/" + id);
    } catch (err) {
      console.log("error occured" + err);
      navigate("/viewMaterial");
    }
  };
  const submit = () => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete? ",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="Move-body">
      <h1 className="title">View Material</h1>
      <p className="undertitle">
        Here you can read more about the material also update and delete
      </p>
      <form className="form" /* onSubmit={(e) => updateMaterial(e)} */>
        <div className="p-underline">
          <p>Information about the material</p>
        </div>
        <div key={material.Id}>
          <a
            href={`https://${hostname}:4000/full/${material.ImgPath}`}
            target="fullimage"
          >
            <img
              className="DetailImage"
              src={`https://${hostname}:4000/thumb/${material.ImgPath}`}
            ></img>
          </a>
          <label className="DetailLabel">Sample Name</label>
          <p className="DetailInput">{material.SampleName}</p>

          <label className="DetailLabel">
            {" "}
            CIMD (Condition and In-depth microstructure details)
          </label>
          <p className="DetailInput">{material.CIMDName}</p>

          <label className="DetailLabel">Material class</label>
          <p className="DetailInput">{material.MatClassName}</p>
          <label className="DetailLabel">Alloy</label>
          <p className="DetailInput">{material.AlloyName} </p>

          <label className="DetailLabel">Production process</label>
          <p className="DetailInput">{material.ProdProcessName}</p>

          <label className="DetailLabel">Magnification</label>
          <p className="DetailInput">{material.Magnification}</p>

          <label className="DetailLabel">Comment</label>
          <p className="DetailTextArea">{material.Comment}</p>
        </div>
      </form>
      <div className="btn-container">
        <button className="Cimd-MatClass-Update" onClick={() => Update()}>
          Update
        </button>
        <button className="Cimd-MatClass-Delete" onClick={() => submit()}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default ViewMaterialDetail;
