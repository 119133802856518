import { axios } from "../../config/axios"

class MaterialService {
     async getAllMaterials() {
        return await axios.get("/material/all").then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getMaterialById(id) {
        return await axios.get("/material/getbyid/"+id)
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async createMaterial(alloy) {
        return await axios.post("/material/create", alloy)
        .catch((err) => console.log(err));
    }
    async updateMaterial(id,material){
        return await axios.put("/material/update/"+id, material)
        .catch((err) => console.log(err));
    }
    async deleteMaterial(id){
        return await axios.delete("/material/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new MaterialService();