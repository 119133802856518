import "../styles/UploadMaterialForm.css";
import CIMDService from "../service/cimdService";
import MatClassService from "../service/matClassService";
import AlloyService from "../service/alloyService";
import ProdProcessService from "../service/prodProcessService";
import React, { useEffect, useState } from "react";
import materialService from "../service/materialService";

const UploadMaterialForm = () => {
  const [cimds, setCimds] = useState([]);
  const [mats, setMats] = useState([]);
  const [alloys, setAlloys] = useState([]);
  const [prods, setProds] = useState([]);
  const [state, setState] = useState("");
  const formData = new FormData();

  useEffect(() => {
    /*  CIMD */
    const fetchCimds = async () => {
      try {
        const response = await CIMDService.getAllCIMDs();
        if (response) setCimds(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchCimds();

    /*  MatClass */
    const fetchMats = async () => {
      try {
        const response = await MatClassService.getAllMatClass();
        if (response) setMats(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchMats();

    /*  Alloy */
    const fetchAlloys = async () => {
      try {
        const response = await AlloyService.getAllAlloys();
        if (response) setAlloys(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchAlloys();

    /*  ProdProcess */
    const fetchProds = async () => {
      try {
        const response = await ProdProcessService.getAllProdProcess();
        if (response) setProds(response.data);
      } catch (err) {
        console.log("error occured");
      }
    };
    fetchProds();
  }, []);

  console.log(prods);

  const handle = (e) => {
    /* const inputData = {...data}; */
    if (e.target.value) formData.set(e.target.id, e.target.value);
    /* inputData[e.target.id] = e.target.value; */
    console.log(formData);
  };
  const handleImage = (e) => {
    if (e.target.value) {
      formData.set(e.target.id, e.target.files[0], e.target.value);
    } else {
      formData.delete(e.target.id);
    }
    console.log(formData);
  };

  const uploadMaterial = async (e) => {
    alert("Your material has been uploaded!");
    try {
      e.preventDefault();
      console.log("It worked!");
      const response = await materialService.createMaterial(formData);
      if (response) window.location.reload(false);
    } catch (err) {
      setState("The material has not been uploaded due to an error!");
      console.log("error occured");
    }
  };

  return (
    <div className="Move-body">
      <h1 className="title">Upload Material</h1>
      <p className="undertitle">Upload material for the Weld-VR environment</p>
      <p className="undertitle">{state}</p>

      <form onSubmit={(e) => uploadMaterial(e)}>
        <div className="p-underline">
          <p>Information about the image of the material</p>
        </div>

        <label>
          Upload image on material * <br />
          <input
            required
            id="image"
            onChange={(e) => handleImage(e)}
            className="textInput"
            type="file"
          />
        </label>

        <div className="p-underline">
          <p>Information about the material</p>
        </div>

        <label>
          Sample Name * <br />
          <input
            required
            id="SampleName"
            onChange={(e) => handle(e)}
            className="textInput"
            type="text"
            placeholder="Name of the sample"
          />{" "}
          <br />
          <br />
        </label>

        {/* TODO value=0 ändra så den inte mappar mot noll / möjligtvis enligt id  */}
        <label>
          CIMD (Condition and In-depth microstructure details) <br />
          <select id="CIMDId" onChange={(e) => handle(e)} className="cimdBox">
            <option value="0">- Empty -</option>
            {cimds.map((cimds) => {
              return (
                <option key={cimds.Id} value={cimds.Id}>
                  {cimds.CIMDName}
                </option>
              );
            })}
          </select>{" "}
          <br />
          <br />
        </label>

        <div className="flex-container">
          <label>
            Material class *
            <select
              required
              id="MatClassId"
              onChange={(e) => handle(e)}
              className="materialBox"
            >
              <option value="0">- Empty -</option>
              {mats.map((mat) => {
                return (
                  <option key={mat.Id} value={mat.Id}>
                    {mat.MatClassName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="labelAlloyBox">
            Alloy
            <select
              id="AlloyId"
              onChange={(e) => handle(e)}
              className="alloyBox"
            >
              <option value="0">- Empty -</option>
              {alloys.map((alloy) => {
                return (
                  <option key={alloy.Id} value={alloy.Id}>
                    {alloy.AlloyName}
                  </option>
                );
              })}
            </select>{" "}
            <br />
            <br />
          </label>
        </div>

        <div className="flex-container">
          <label>
            Production process
            <select
              id="ProdProcessId"
              onChange={(e) => handle(e)}
              className="prodBox"
            >
              <option value="0">- Empty -</option>
              {prods.map((prod) => {
                return (
                  <option key={prod.Id} value={prod.Id}>
                    {prod.ProdProcessName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="labelMagBox">
            Magnification
            <input
              id="Magnification"
              onChange={(e) => handle(e)}
              className="magBox"
              type="number"
            />{" "}
            <br />
            <br />
          </label>
        </div>

        <label className="labelTextarea">
          Comment <br />
          <textarea
            id="Comment"
            onChange={(e) => handle(e)}
            className="textareaBox"
            placeholder="Comment about the material"
          ></textarea>{" "}
          <br />
        </label>

        <input className="uploadBtn" type="submit" value="Upload Material" />
      </form>
    </div>
  );
};

export default UploadMaterialForm;
