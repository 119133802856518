import React from "react";
import cimdService from "../../../service/cimdService";
import "../../../styles/MaterialTypes.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const CIMDList = () => {
  const [cimds, setCIMD] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCIMD = async () => {
      try {
        const response = await cimdService.getAllCIMDs();
        if (response) {
          setCIMD(response.data);
        }
      } catch (err) {
        console.log("error occured" + err);
      }
    };
    getCIMD();
  }, []);

  const Delete = async (id) => {
    try {
      await cimdService.deleteCIMD(id);
      window.location.reload(false);
    } catch (err) {
      console.log("error occured" + err);
    }
  };
  const submit = (props) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete? " + props.CIMDName,
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(props.Id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="CIMD-div">
      <Link to="/materialtypes/cimd/create">
        <button className="createCimdMatClass">Create New CIMD Type</button>
      </Link>
      <div className="CIMD-MatClass-div">
        <h1>Condition and In-depth Microstructure Details</h1>
        <table>
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cimds.map((cimd) => {
              return (
                <tr key={cimd.Id}>
                  {/* <td>{cimd.Id}</td> */}
                  <td className="tdName">{cimd.CIMDName}</td>
                  <td>
                    <button
                      className="Cimd-MatClass-Update"
                      onClick={() => navigate("update/" + cimd.Id)}
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="Cimd-MatClass-Delete"
                      onClick={() => submit(cimd)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CIMDList;
