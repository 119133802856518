import { axios } from "../../config/axios"

class ProdProcessService {
     async getAllProdProcess() {
        return await axios.get("/prodprocess/all").then(res => {return res})
        .catch((err) => console.log(err));
    }
    async getProdProcessById(id) {
        return await axios.get("/prodprocess/getbyid/"+id)
        .then(res => {return res})
        .catch((err) => console.log(err));
    }
    async createProdProcess(prodprocess) {
        return await axios.post("/prodprocess/create", prodprocess)
        .catch((err) => console.log(err));
    }
    async updateProdProcess(id,prodprocess){
        return await axios.put("/prodprocess/update/"+id, prodprocess)
        .catch((err) => console.log(err));
    }
    async deleteProdProcess(id){
        return await axios.delete("/prodprocess/delete/"+id)
        .catch((err) => console.log(err));
    }
}

export default new ProdProcessService();