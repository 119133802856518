import "../styles/ViewMaterial.css";
import React, { useEffect, useState } from "react";
import materialService from "../service/materialService";
import { useNavigate } from "react-router-dom";
import MaterialCard from "../layouts/MaterialCard"; // Importera MaterialCard här

const ViewMaterial = () => {
  const [material, setMaterial] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [search, setSearchData] = useState([]);
  const navigate = useNavigate();
  const hostname = window.location.hostname;

  useEffect(() => {
    const getMaterial = async () => {
      try {
        const response = await materialService.getAllMaterials();
        if (response) {
          setMaterial(response.data);
          setSearchData(response.data);
        }
      } catch (err) {
        console.log("error occured");
      }
    };

    getMaterial();
  }, []);

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setMaterial(search);
    } else {
      const filterResult = search.filter((material) =>
        material.SampleName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setMaterial(filterResult);
    }
    setFilterData(e.target.value);
  };

  return (
    <div className="Move-body">
      <h1 className="title">View Material</h1>
      <p className="undertitle">
        Choose below which material you would like to view
      </p>
      <div>
        <input
          className="filterbox"
          placeholder="Filter on material class"
          value={filterData}
          onInput={(e) => handleFilterData(e)}
        ></input>
      </div>
      <div className="materialContainer">
        {material.map((material) => (
          <MaterialCard
            key={material.Id}
            material={material}
            navigate={navigate}
            hostname={hostname}
          />
        ))}
      </div>
    </div>
  );
};

export default ViewMaterial;
