import React from "react";
import "../../styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const DebugNav = ({ links }) => {
  const navigate = useNavigate();

  const { auth, setAuth } = useAuth();

  return (
    <div>
      <nav className="nav-side">
        <ul>
          <div className="Logo-background">
            <Link to="/home" className="hvBild-link">
              <img
                src="https://i.imgur.com/RBisH2N.png"
                className="hvBild"
                alt="WELD-VR Logo"
              />
            </Link>
          </div>

          {links.map((link) => {
            if (link.toLowerCase() === "home") {
              return (
                <Link key={link} to="/home">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "debug") {
              return (
                <Link key={link} to="/debug">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "create material template") {
              return (
                <Link key={link} to="/materialtypes/prodprocess">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "view material") {
              return (
                <Link key={link} to="/viewMaterial">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "upload material") {
              return (
                <Link key={link} to="/uploadMaterialForm">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "upload powerpoint") {
              return (
                <Link key={link} to="/UploadPowerpoint">
                  <li>{link}</li>
                </Link>
              );
            } else if (link.toLowerCase() === "contact") {
              return (
                <a
                  key={link}
                  href="https://www.hv.se/forskning/forskningsprojekt/teknik/weldvr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>{link}</li>
                </a>
              );
            } else {
              return null;
            }
          })}
        </ul>
        <div className="hvBild-container">
          <div className="button-container">
            {auth?.loggedIn ? (
              <button className="signOutBtn" onClick={() => navigate("logout")}>
                Sign out
              </button>
            ) : (
              <button
                className="loginBtn"
                onClick={() => navigate("login")}
              ></button>
            )}
          </div>
          <div className="hvBild-background">
            <img
              src="https://i.imgur.com/ZMmPxLp.png"
              className="hvBild"
              alt="HV Logo"
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DebugNav;

//sjdadj
