import "../styles/Login.css";
import React, { useEffect, useState } from "react";
import UserService from "../service/userService";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
// import { useContext } from "react";
// import UserContext from "../context/userContext";

const Logout = () => {
  const navigate = useNavigate();
  const formData = new FormData();
  const [user, setUser] = useState();
  const { auth, setAuth } = useAuth();
  const [showPwd, setShowPwd] = useState(false); /* show/hide password */

  useEffect(() => {
    formData.set("Username", "logout");
    formData.set("HashPass", "none");
    const logoutUser = async () => {
      const response = await UserService.getUserLogin(formData);
      if (response.data) {
        console.log("login response:", response.data);
      }
    };

    try {
      logoutUser();
      navigate("/login");
    } catch (err) {
      console.log("error occured");
    }
    setAuth({ loggedIn: false, user: "" });
  }, []);

  return <div className="Logout"></div>;
};

export default Logout;
