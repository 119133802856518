import "../../../styles/UploadMaterialForm.css";
import CIMDService from "../../../service/cimdService";
import "../../../styles/MaterialTypes.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateCIMDForm = () => {
  const [message, setMessage] = useState("");
  const formData = new FormData();
  const navigate = useNavigate();

  const handle = (e) => {
    if (e.target.value) formData.set(e.target.id, e.target.value);
  };

  const createCIMD = async (e) => {
    try {
      e.preventDefault();
      console.log("uploaded:" + formData);
      const response = await CIMDService.createCIMD(formData);
      if (response) navigate("/materialtypes/cimd");
    } catch (err) {
      setMessage("A problem has occured");
      console.log("error occured");
    }
  };

  return (
    <div>
      <form className="matForm" onSubmit={(e) => createCIMD(e)}>
        <h2 className="title">Create CIMD type</h2>
        <p className="undertitle">{message}</p>
        <p className="undertitle">
          Create "Condition and In-depth microstructure details" type for the
          Weld-VR environment that can be used in a material
        </p>
        <label className="labelTextarea">CIMD Name</label>
        <input
          id="CIMDName"
          onChange={(e) => handle(e)}
          className="textInput"
          type="text"
          placeholder="Name of the sample"
        />{" "}
        <br />
        <input className="uploadBtn" type="submit" value="Create" />
      </form>
    </div>
  );
};

export default CreateCIMDForm;
