import React from "react";
import "../../styles/MaterialTypes.css";
import { Link, Outlet } from "react-router-dom";

const MaterialTypes = () => {
  return (
    <div className="Move-body">
      <h1 className="title">Material types</h1>
      <p className="undertitle">
        Here you can create, update and delete materialtypes.
        <br /> Choose materialtype and then choose item in the table.
        <br /> The suggested order in which to create materialtypes is marked
        with numbers.
      </p>
      <nav className="materialNav">
        <div>
          <p>1</p>
          <Link to={"/materialtypes/prodprocess"}>
            <button className="MatClassBtn">Prod Process</button>
          </Link>
        </div>
        <div>
          <p>2</p>
          <Link to={"/materialtypes/materialclass"}>
            <button className="MatClassBtn">Material Class</button>
          </Link>
        </div>
        <div>
          <p>3</p>
          <Link to={"/materialtypes/alloy"}>
            <button className="MatClassBtn">Alloys</button>
          </Link>
        </div>
        <div>
          <p>4</p>
          <Link to={"/materialtypes/cimd"}>
            <button className="CimdBtn">CIMD</button>
          </Link>
        </div>
      </nav>
      {/* <hr/> */}
      <Outlet />
    </div>
  );
};

export default MaterialTypes;
